import * as React from "react";

import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { isEmpty, size } from "lodash";

import { ImageOptions as ImageOptionsType } from "./index";
import { PageWidgetBaseProps } from "../pageWidget.types";
import { ReactIdSwiperProps } from "react-id-swiper";
import { VideoPlayer } from "./VideoPlayer";
import { WidgetOptions } from "./";
import loadable from "@loadable/component";
import { loadableRetry } from "../../../utils/loadableComponents.util";
import { renderThumbnailWithPlayButton } from "./imageGalleryVideo.utils";

const Swiper = loadable(() => loadableRetry(() => import("react-id-swiper")), {
    resolveComponent: (Swiper) => Swiper.default as React.ForwardRefExoticComponent<any>,
});

interface GalleryModalProps extends PageWidgetBaseProps<WidgetOptions> {
    imageOptions: ImageOptionsType[];
    showVideoModal?: boolean;
    handleThumbnailVideo: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, modalVideoUrl?: string) => void;
    slidePosition: string;
    imageModalTitle: string;
    imageOrignalUrl?: string;
    showModal: boolean;
    getImageUrl: (arg: string) => string;
    getVideoUrl: (arg: string) => string;
    onPauseVideo: () => void;
    handleModal: () => void;
}

export class GalleryModal extends React.PureComponent<GalleryModalProps> {
    constructor(props: GalleryModalProps) {
        super(props);
    }

    private getSliderParams = (): ReactIdSwiperProps => {
        const {
            options: { slideIndex, dynamicIndicators },
            slidePosition,
        } = this.props;
        const sliderParams: ReactIdSwiperProps = {};
        sliderParams.preloadImages = false;
        sliderParams.lazy = true;
        sliderParams.spaceBetween = 0;
        sliderParams.slidesPerView = 1;
        sliderParams.loop = true;
        sliderParams.navigation = { nextEl: ".swiper-button-next", prevEl: ".swiper-button-prev" };
        sliderParams.activeSlideKey = slidePosition;

        if (slideIndex === "dots per slide") {
            sliderParams.pagination = {
                el: ".swiper-pagination",
                clickable: true,
                dynamicBullets: dynamicIndicators ? true : false,
            };
        } else {
            sliderParams.pagination = {
                el: ".swiper-pagination",
                type: "fraction",
            };
        }
        return sliderParams;
    };

    private renderModalSlide = () => {
        const {
            imageModalTitle,
            imageOptions,
            getImageUrl,
            getVideoUrl,
            options: { dynamicImageSize, useOverlayThumbnailForVideo },
            imageOrignalUrl,
            showVideoModal,
            handleModal,
            handleThumbnailVideo,
        } = this.props;
        return imageOptions.map((options: ImageOptionsType, index: number) => {
            if (!isEmpty(options)) {
                const { alt, friendlyName, height, id, imageUrl, mediaType, url, width } = options;
                const videoUrl = getVideoUrl(url);
                let modalImageUrl = imageUrl;
                if (dynamicImageSize) {
                    modalImageUrl = imageUrl.replace(/\/t.*\/f/, "t_mcms_larger/f");
                }
                return (
                    <div className="card" key={index}>
                        {mediaType === "IMAGE" ? (
                            <figure>
                                <img
                                    className="swiper-lazy card-img-top"
                                    loading="lazy"
                                    key={id}
                                    src={getImageUrl(modalImageUrl || "")}
                                    alt={alt || friendlyName ? alt || friendlyName : imageModalTitle}
                                />
                                <div className="swiper-lazy-preloader"></div>
                                <figcaption>{alt || friendlyName}</figcaption>
                            </figure>
                        ) : options.url ? (
                            useOverlayThumbnailForVideo ? (
                                renderThumbnailWithPlayButton(options, options.mediaType === "VIDEO" ? videoUrl : options.url, handleThumbnailVideo, showVideoModal, imageOrignalUrl)
                            ) : (
                                <VideoPlayer url={mediaType === "VIDEO" ? videoUrl : url} height={`${height}`} width={`${width}`} />
                            )
                        ) : null}
                    </div>
                );
            }
        });
    };

    private renderModalContent = () => {
        const { imageOptions, onPauseVideo } = this.props;
        const sliderParams = this.getSliderParams();

        if (size(imageOptions) > 1) {
            return (
                <Swiper {...sliderParams} className="statis-img-slider" slideChange={onPauseVideo}>
                    {this.renderModalSlide()}
                </Swiper>
            );
        }
        return <div>{this.renderModalSlide()}</div>;
    };

    public render(): JSX.Element | null {
        const { imageModalTitle, showModal, handleModal } = this.props;
        return (
            <Modal isOpen={showModal} toggle={handleModal} size="md" className="page-modal">
                <ModalHeader tag="h4" toggle={handleModal}>
                    {imageModalTitle}
                </ModalHeader>
                <ModalBody>
                    <section className="filter-wrapper popup-slider">{this.renderModalContent()}</section>
                </ModalBody>
            </Modal>
        );
    }
}
